<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" :loading="loading"/>
    <div v-else class="tw-flex tw-w-full tw-flex-col
         lg:tw-py-5  tw-mt-20  lg:tw-mt-5 table-container-div tw-p-0 lg:tw-pl-8">
    <div  class="tw-flex tw-w-full tw-justify-end tw-items-center" v-if="userData.userRole === 'transportCompanyManager'">
      <button class="add-trip-btn" @click="addTrip" :style="{backgroundColor:companyColor}">
        Add Trip
      </button>
    </div>
    <div
        class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5 tw-mt-8 table-container-div tw-px-4 lg:tw-pl-8 "
        style="background: #FDFFFC;"
    >
      <div class="table-container lg:tw-pr-8" >
        <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">

          <v-btn v-for="(btnText,index) in buttons" :key="index"
                 outlined class="tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5 hover-btn text-capitalize"
                 :style="{background : isActiveButton === btnText ? companyColor: ''}"
                 :color="isActiveButton === btnText ? 'white': ''" @click="handleBtnClick(btnText)"
          >{{btnText}}</v-btn>

          <ValidationProvider
              name="Trip Date"
              rules="required"
              v-slot="{ classes, errors }"
          >

            <div class="tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5" :class="classes"       style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                ">
              <v-menu
                  ref="fromDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"

              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-on="on"
                      placeholder="Search trip by date"
                      solo
                      flat
                      hide-details
                      readonly
                      class="p-0"

                  >
                    <template #prepend-inner>
                      <v-icon :color="companyColor" class="">
                        mdi-calendar-month-outline
                      </v-icon>
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                    locale="en-in"
                    v-model="tripDate"
                    no-title
                    :color="companyColor"

                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      :color="companyData.companyColor"
                      @click="fromDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text :color="companyData.companyColor"
                         @click="handleOkButtonClick">OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
            <div
                class="mr-2"
                style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
            >
              <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  color="#FDFFFC"
                  v-model="search"
              >
                <template #prepend>
                  <v-icon :color="companyColor" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>
                <template #append>
                  <v-icon :color="companyColor"> mdi-filter-variant </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
        </div>
        <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="false"
            height="available"
            :hide-default-footer="true"
            :items="trips"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            :items-per-page.sync="paginator.itemsPerPage"
            :page.sync="paginator.page"
            @click:row="viewSingle"

        >
          <template #footer v-if="trips.length > 10">
            <div class="tw-flex tw-w-full tw-justify-end tw-items-center td-style">
              Items per Page
              <div  class="tw-mx-3 tw-w-1/12">
                <v-select hide-details solo flat :items="itemsPerPage" v-model="paginator.itemsPerPage"></v-select>
              </div>
              Page {{paginator.page}} of {{numberOfPages}}
              <v-btn @click="handlePrevious" text fab small :disabled="paginator.page===1">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn @click="handleNext" text fab small :disabled="paginator.page === numberOfPages" :loading="pageDataLoading">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>

            </div>
          </template>
          <template #header.sno>
            <span class="status"> S/N </span>
          </template>
          <template v-slot:[`item.sno`] = "{item}">
            <span class="td-style">{{item.sno}}</span>
          </template>
          <template #header.fleet >
            <span class="status"> Fleet </span>
          </template>
          <template v-slot:[`item.fleet`] =  "{item}" >
            <span v-if="item.fleetForThisTrip" class="td-style">{{item.fleetForThisTrip.vehicleRegNumber}}</span>
            <span v-else class="td-style">UNASSIGNED </span>
          </template>
          <template #header.route>
            <span class="status">Route</span>
          </template>
          <template v-slot:[`item.route`]="{ item }">
            <span class="td-style text-capitalize">{{ toSentenceCase(item.routeDepartureTerminalName) }}
                {{ toSentenceCase(item.routeDepartureCity) }} {{ toSentenceCase(item.routeDepartureState) }} -
                {{ toSentenceCase(item.routeDestinationTerminalName) }}
                {{ toSentenceCase(item.routeDestinationCity) }}
                {{ toSentenceCase(item.routeDestinationState) }}</span>
          </template>
          <template #header.date>
            <span class="status"> Take off Date </span>
          </template>
          <template v-slot:[`item.date`] = "{item}">
            <span class="td-style">{{getDate(item.takeOffDate)}}</span>
          </template>
          <template #header.time >
            <span class="status"> Time </span>
          </template>
          <template v-slot:[`item.time`] =  "{item}">
            <span class="td-style">{{getTime(item.takeOffDate,item.takeOffTime)}}</span>
          </template>
          <template #header.createdAt >
            <span class="status"> Creation Date </span>
          </template>
          <template v-slot:[`item.createdAt`] =  "{item}">
            <span class="td-style">{{getDateFromDatetime(item.createdAt)}}</span>
          </template>
          <template #header.status >
            <span class="status"> Status </span>
          </template>
          <template v-slot:[`item.status`] =  "{item}">
            <span class="status-div" :style="{backgroundColor: getColor(item.status)}" >{{item.status}}</span>
          </template>
          <template #header.price >
            <span class="status"> Price </span>
          </template>
          <template v-slot:[`item.price`] =  "{item}">
              <span class="td-style" v-if="item.tripRegion === 'local' || !item.tripRegion"> &#8358; {{ item.tripAmount }}</span>
              <div v-if="item.tripRegion === 'crossCountry' && item.tripCategories.length">
                  <span class="td-style text-capitalize" v-for="(category,index) in item.tripCategories" :key="index">
                      &#8358; {{ category.tripAmount }}</span>
              </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom offset-y left v-if="item.mainDropOff">
              <template v-slot:activator="{ on, attrs }">
                <td class="d-flex justify-center">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </td>
              </template>
              <v-list>
                <v-list-item class="tw-flex-row tw-flex tw-cursor-pointer" @click="viewSingle(item)">
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex menu-text tw-items-center"
                  >
                    View
                    <v-icon :color="companyColor">mdi-arrow-right</v-icon>

                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="tw-flex-row tw-flex tw-cursor-pointer" @click="handleShowAssign(item)"
                             v-if="item.status === 'PENDING'
                             && userData.userRole === 'transportCompanyManager'
                             && item.terminalId === staffData.terminalAssignedId">
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex menu-text"
                  >
                    <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                    />
                    Assign Fleet
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="" @click="showCancelModal(item.terminalId,item.id)" v-if="item.status === 'PENDING'
                              && userData.userRole === 'transportCompanyManager'
                             && item.terminalId === staffData.terminalAssignedId">
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer menu-text"
                  >
                    <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                    />
                    Cancel
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="" @click="endTrip(item.routeDestinationId,item.id)" v-if="item.status === 'ONGOING'
                          && userData.userRole === 'transportCompanyManager'
                             && item.terminalId === staffData.terminalAssignedId" >
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer menu-text tw-items-center">
                      <img
                          src="@/assets/icons/edit-icon.svg"
                          alt=""
                          class="tw-mr-2 icon-size"
                      />
                      End Trip
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="item.status === 'DRAFT' || item.status === 'PENDING'"
                    @click="handleShowUpdateTripAmount(item.id)"
                >
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex"
                  >
                    <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2"
                    />
                    Update Amount
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status === 'DRAFT' && userData.userRole === 'transportCompanyManager'
                             && item.terminalId === staffData.terminalAssignedId" @click="handleShowDelete(item.id)">
                  <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex menu-text"
                  >
                    <img
                        src="@/assets/icons/del-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                    />
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>
    <assign-modal :dialog="showAssign" title="Assign a fleet to a trip"
                  :trip-id="tripId" :tripTerminalId="terminalId"
                  @close="handleShowAssign" assign-type="trip"
                  @reloadAllTrips="getAllTrips"/>

    <modal :dialog="showCancel" section="cancelling"
           :color="companyColor"
           @close="showCancelModal"
           @cancelTrip="cancelTrip" :loading="cancelModalLoading" />
      <modal
          :dialog="showUpdateTripAmount"
          section="update-trip-amount"
          :color="companyColor"
          @close="closeUpdateTripAmountModal"
          @updateTripAmount="updateAmount"
          :loading="showUpdateTripAmountLoading"
      />

    <delete-modal :dialog="showDelete"
                  description="Are you sure you want to delete this trip"
                  @deleteAction="deleteTrip" @close="handleShowDelete" :done-loading="deleteLoading" />
    </div>
  </v-col>
</template>

<script>
import {
  cancelTrip,
  deleteTrip, getAllTripsByPage,
  getTotalTripCountByTransportCompany,
  tripCompleted
} from "@/services/api/APIService";
import dayjs from "dayjs";
import AssignModal from "../../reuseables/AssignModal";
import Modal from "../../reuseables/Modal";
import DeleteModal from "../../reuseables/DeleteModal";
import Loader from "../../reuseables/Loader";
import {getAllTransportCompanyTripsByDate, updateTripAmount} from "../../../services/api/APIService";

export default {
  name: "TerminalManagerAllFleets",
  components: {Loader, DeleteModal, Modal, AssignModal},
  data() {
    return {
      buttons:["all trips","yesterday","today","tomorrow"],
      isActiveButton: 'all trips',
      fromDateMenu: false,
      tripDate: null,
      searchTripDate: "",
      pageNo: 0,
      pageSize: 200,
      companyColor: "#004AAD",
      companyData: {},
      trips: [],
      headers: [
        {text: "", value: "sno"},
        { text: "", value: "fleet" },
        { text: "", value: "route" },
        { text: "", value: "date" },
        { text: "", value: "time" },
        { text: "", value: "createdAt" },
        { text: "", value: "status" },
        { text: "", value: "price" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Trips will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      loading: false,
      terminalId: null,
      showAssign : false,
      tripId: null,
      mostRecent:{},
      reasonForCancelling: "",
      showCancel: false,
      cancelTerminalId : null,
      cancelTripId : null,
      cancelModalLoading:false,
      completedTripLoading: false,
      showDelete: false,
      deleteLoading: false,
      userData:{},
      staffData:{},
      paginator:{
        page: 1,
        itemsPerPage: 10,
        pageStart: 1,
        pageStop: 10,
        pageCount: 1,
        itemsLength: 0
      },
      itemsPerPage:[5,10,20,50,100],
      totalTripCount : 0,
      pageData:{},
      pageDataLoading: false,
      showUpdateTripAmount:false,
      showUpdateTripAmountLoading:false

    };
  },
  computed:{
    numberOfPages () {
      return Math.ceil(this.trips.length / this.paginator.itemsPerPage)
    },
    filterMatchedTrips() {
      return this.trips.filter((trip) => {
        return !this.search || (trip.routeDepartureCity.toLowerCase().includes(this.search))
            || (trip.routeDestinationCity.toLowerCase().includes(this.search));
      })
    },
  },
  methods: {
    handleBtnClick(btnText){
      sessionStorage.setItem("activeButton",btnText)
      this.isActiveButton= btnText
      if (btnText === 'all trips'){
        this.getAllTrips(true)
      }
      else {
        this.getAllTripsByDate(btnText)
      }
    },
    getColor(status){
      if (status.toUpperCase() === "PENDING"){
        return "#A828B4"
      }
      else if (status.toUpperCase() === "ONGOING"){
        return "#4CD171"
      }
      else if (status.toUpperCase() === "COMPLETED"){
        return "#1C60BC"
      }
      else if (status.toUpperCase() === "CANCELED"){
        return "#F54848"
      }
      else {
        return "#2E2E2E"
      }
    },
    updateAmount(tripData){
      this.showUpdateTripAmountLoading = true
      tripData.id = this.tripId
      updateTripAmount(tripData).then(() =>{
        this.showUpdateTripAmountLoading = false
        this.showUpdateTripAmount = false
        window.location.reload()
      }).catch((err) => {
        this.showUpdateTripAmountLoading = false;
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    handleShowUpdateTripAmount(tripId) {
      if (!this.showUpdateTripAmount) {
        this.tripId = tripId;
      }
      this.showUpdateTripAmount = !this.showUpdateTripAmount;
    },
    closeUpdateTripAmountModal(){
      this.showUpdateTripAmount = !this.showUpdateTripAmount
    },

    cancelTrip(reasonForCancelling){
      this.cancelModalLoading = true
      let data = {}
      data.tripId = this.cancelTripId
      data.transportCompanyId = this.companyData.id
      data.terminalId = this.cancelTerminalId
      data.reasonForCancelling = reasonForCancelling
      cancelTrip(data).then(res =>{
        this.$displaySnackbar({
          message: res.data.Details,
          success: true,
        });
        this.cancelModalLoading = false
        this.showCancelModal()
        this.getAllTrips()
      })
          .catch((err) => {
            this.cancelModalLoading = false;
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });

    },
    endTrip(newTerminalId, tripId){
      this.completedTripLoading = true;
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.newTerminalId = newTerminalId
      data.tripId = tripId
      tripCompleted(data).then(res =>{
        this.$displaySnackbar({
          message: res.data.Details,
          success: true,
        });
        this.completedTripLoading = false;
        this.getAllTrips()
      }).catch((err) => {
        this.completedTripLoading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    deleteTrip(){
      this.deleteLoading = true
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.tripId = this.tripId
      deleteTrip(data).then(res =>{
        console.log(res.data)
        this.deleteLoading = false;
        this.handleShowDelete()
        this.getAllTrips()
      }).catch((err) => {
        this.deleteLoading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    handleShowDelete(tripId){
      if (!this.showDelete){
        this.tripId = tripId
      }
      this.showDelete = !this.showDelete
    },
    showCancelModal(terminalId,tripId){
      if (terminalId){
        this.cancelTerminalId = terminalId
        this.cancelTripId = tripId
      }
      this.showCancel = !this.showCancel
    },
    addTrip() {
      this.$store.dispatch(
        "terminalManagerTripComponent/setCurrentComponent",
        "addTrip"
      );
      sessionStorage.setItem("terminalManagerTripComponent", "addTrip");
    },
    viewSingle(trip) {
      this.$store.dispatch("terminalManagerTripComponent/setCurrentComponent", "singleTrip");
      sessionStorage.setItem("terminalManagerTripComponent", "singleTrip");
      sessionStorage.setItem("trips", JSON.stringify(this.trips));
      sessionStorage.setItem("currentTripId", trip.id)
    },
    handleShowAssign(trip){
      if (trip){
        this.tripId = trip.id
        this.terminalId = trip.terminalId
      }
      this.showAssign = !this.showAssign
    },
    async getAllTrips(showLoading) {
      if (showLoading) {
        this.loading = true;
      }
      else {
        this.pageDataLoading = true
      }
      await getAllTripsByPage(this.pageData).then((res) => {
        if (this.trips.length){
          this.trips = this.trips.concat(res.data)
        }
        else {
          this.trips = res.data
        }
        this.mostRecent = this.trips[0];
        this.trips.sort(function (a, b) {
          return (
              dayjs(b.takeOffDate + b.takeOffTime).toDate() -
              dayjs(a.takeOffDate + a.takeOffTime).toDate()
          );
        });
        this.trips.forEach((trip, index) => (trip.sno = index + 1));
        this.paginator.itemsLength = this.trips.length
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
    },
    async getAllTripsByDate(selectedDate){
      this.loading = true;
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.searchTripDate = this.searchTripDate
      data.page = this.pageNo
      data.pageSize = this.pageSize
      if (selectedDate === 'today'){

        data.searchTripDate = dayjs().format("YYYY-MM-DD")
      }
      else  if(selectedDate === 'yesterday'){

        data.searchTripDate = dayjs().subtract(1,"day").format("YYYY-MM-DD");
      }
      else if(selectedDate === 'tomorrow'){
        data.searchTripDate = dayjs().add(1,"day").format("YYYY-MM-DD");
      }
      else {
        data.searchTripDate = selectedDate
      }

      await getAllTransportCompanyTripsByDate(data).then(res =>{
        this.loading = false
        let activeButton = sessionStorage.getItem("activeButton")
        if (activeButton){
          this.isActiveButton = activeButton
        }
        this.trips = res.data
      }).catch((err)=>{
        this.loading = false
        console.log(err.response)
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      })
    },

    handleOkButtonClick(){
      sessionStorage.setItem("activeButton",'')
      this.isActiveButton= ''
      this.getAllTripsByDate(this.tripDate)
    },
    handleNext(){
      if (this.paginator.page < this.trips.length){
        this.paginator.page++
        if (this.paginator.page === this.numberOfPages && this.trips.length < this.totalTripCount){
          this.pageData.page++
          this.getAllTrips(false).then(()=> this.pageDataLoading = false)

        }
      }
    },
    handlePrevious () {
      if (this.paginator.page > 1)
        this.paginator.page--
    },
    async getTotalTripCount(){
      await getTotalTripCountByTransportCompany(this.companyData.id).then(res =>{
        this.totalTripCount = res.data.totalTrip
      }).catch((err) => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    },
    getTime(date, time) {
      return dayjs(date+time,'ll').format('LT')
    },
    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },
    getDateFromDatetime(datetimeStr) {
      const date = dayjs(datetimeStr).toDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const convertDate = `${year}-${month}-${day}`;
      return this.getDate(convertDate);
    },
      toSentenceCase(text){
          if (text) {
              text = text.toLowerCase()
              return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
          }
      },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
      if (userData){
        if (userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER")){
          userData.userRole= "transportCompanyManager"
        }
        else {
          userData.userRole = "transportCompanyBookingAdmin"
        }
        sessionStorage.setItem("userData",JSON.stringify(userData))
        this.userData = userData
      }
    if (staffData){
      this.staffData = staffData
    }
    this.pageData.transportCompanyId = this.companyData.id
    this.pageData.page = 0
    this.pageData.pageSize= 100
    await this.getTotalTripCount()
    await this.getAllTrips(true)
  },
};
</script>

<style lang="scss" scoped>
.add-trip-btn {
  height: 32px;
  width: 138px;
  color: white;
  border-radius: 6px;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  p {
    font-family: "Inter", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}


::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}
.menu-text{
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
}
.icon-size{
  height: 16px;
  width: auto;
}

.status-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25px;
  padding: 0.5rem;
  border-radius: 20px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #FDFFFC;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}

.hover-btn{
  color: rgba(79, 79, 79, 0.5);
  height: 45px !important;
  border-radius: 10px;
  width: 140px;
  text-transform: none;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.hover-btn:hover{
  color: white;
  background: #1E5770;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
</style>
